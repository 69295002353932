// measure.js
// Added for measurement feature
import * as THREE from 'three';

export class Measure {
    constructor(viewer) {
        this.viewer = viewer;
        this.isSelecting = false;
        this.selectionStart = new THREE.Vector2();
        this.selectionBox = [];
        this.drawedPoints = [];
        this.lines = [];
        this.distanceInfos = [];
        this.selectedDistBoxInfos = new Set();
        this.selectedPoints = new Set();
        this.selectedLines = new Set();
        this.clickedPoints = [];
        this.showCoordinates = false;

        // settings
        this.lineWidthSetting = 0.05;
        this.pointSizeSetting = 10;
        this.scaleFactorSetting = 1.0;
        this.settingDialogClosed = true;

    }

    createPoint(position, color = 0xff0000) {
        const vertexShader = `
            uniform float size;
            uniform float near;
            void main() {
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * mvPosition;
                gl_Position.z = -(near * 1.05);
                gl_PointSize = size;
            }
        `;

        const fragmentShader = `
            uniform vec3 color;
            void main() {
                float dist = length(gl_PointCoord - vec2(0.5, 0.5));
                if (dist < 0.5) {
                    gl_FragColor = vec4(color, 1.0);
                } else {
                    discard;
                }
            }
        `;

        const uniforms = {
            size: { value: this.pointSizeSetting },
            near: { value: this.viewer.camera.near },
            color: { value: new THREE.Color(color) }
        };

        const material = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            transparent: true
        });

        const geometry = new THREE.BufferGeometry();
        const vertices = new Float32Array([position.x, position.y, position.z]);
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        const point = new THREE.Points(geometry, material);
        this.viewer.threeScene.add(point);
        this.drawedPoints.push({ position: position, point: point });

        if (this.showCoordinates) {
            this.displayCoordinates(point, position);
        }

        return point;
    }

    getRaycastIntersect() {
        const renderDimensions = new THREE.Vector2();
        const outHits = [];
        this.viewer.forceRenderNextFrame();
        this.viewer.getRenderDimensions(renderDimensions);
        outHits.length = 0;
        this.viewer.raycaster.setFromCameraAndScreenPosition(this.viewer.camera, this.viewer.mousePosition, renderDimensions);
        this.viewer.raycaster.intersectSplatMesh(this.viewer.splatMesh, outHits);
        if (outHits.length > 0) {
            return outHits[0].origin;
        } else {
            return null;
        }
    }

    createCoordinateLabel(point, position) {
        const coordDiv = document.createElement('div');
        coordDiv.style.position = 'absolute';
        coordDiv.style.color = '#ffffff';
        coordDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
        coordDiv.style.padding = '5px';
        coordDiv.style.fontSize = '12px';
        coordDiv.style.borderRadius = '4px';
        coordDiv.style.border = '1px solid #ffffff';
        coordDiv.style.pointerEvents = 'none';
        coordDiv.style.fontFamily = 'Arial, sans-serif';
        coordDiv.style.transform = 'translate(-50%, -50%)';
        coordDiv.innerHTML = `(${position.x.toFixed(2)}, ${position.y.toFixed(2)}, ${position.z.toFixed(2)})`;
        document.body.appendChild(coordDiv);

        const updateCoordDivPosition = () => {
            const vector = point.position.clone().project(this.viewer.camera);
            const x = (vector.x * 0.5 + 0.5) * window.innerWidth;
            const y = -(vector.y * 0.5 - 0.5) * window.innerHeight;
            coordDiv.style.left = `${x}px`;
            coordDiv.style.top = `${y}px`;
        };

        point.userData.coordDiv = coordDiv;
        point.updateCoordDivPosition = updateCoordDivPosition.bind(this);
        updateCoordDivPosition();
    }

    createModePanel() {
        this.modePanel = document.createElement('div');
        this.modePanel.id = 'modePanel';
        this.modePanel.style.position = 'absolute';
        this.modePanel.style.left = '10px';
        this.modePanel.style.bottom = '10px';
        this.modePanel.style.padding = '10px';
        this.modePanel.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        this.modePanel.style.color = 'white';
        this.modePanel.style.fontFamily = 'Arial, sans-serif';
        this.modePanel.style.fontSize = '14px';
        this.modePanel.style.borderRadius = '5px';
        this.modePanel.style.pointerEvents = 'none';
        document.body.appendChild(this.modePanel);
        this.updateModePanel();
    }

    updateModePanel() {
        this.modePanel.innerText = `Measure Mode: ${this.viewer.MeasureMode ? 'ON' : 'OFF'}`;
    }

    handleDrawClick(mouse) {
        const mouseVec = new THREE.Vector2();
        mouseVec.x = (mouse.offsetX / window.innerWidth) * 2 - 1;
        mouseVec.y = -(mouse.offsetY / window.innerHeight) * 2 + 1;
        let intersect = this.getRaycastIntersect();
        if (intersect != null) {
            console.log(`intersect coordinates ${intersect}`);
            if (this.clickedPoints.length < 2) {
                this.createPoint(intersect);
                this.clickedPoints.push(intersect);
            }

            if (this.clickedPoints.length === 2) {
                const material = new THREE.LineBasicMaterial({ color: 0xff0000, lineWidth: this.lineWidthSetting });
                const geometry = new THREE.BufferGeometry().setFromPoints(this.clickedPoints);
                const line = new THREE.Line(geometry, material);
                this.viewer.threeScene.add(line);
                this.lines.push(line);

                const distance = this.clickedPoints[0].distanceTo(this.clickedPoints[1]) * this.scaleFactorSetting;
                const distanceBox = this.createDistanceBox(distance, this.clickedPoints);
                this.distanceInfos.push({ element: distanceBox, line: line, value: distance });
                this.clickedPoints = [];
            }
        }
    }

    createDistanceBox(distance, points) {
        const midPoint = new THREE.Vector3().addVectors(points[0], points[1]).multiplyScalar(0.5);
        const text = `${distance.toFixed(3)} m`;

        const distBox = document.createElement('div');
        distBox.style.position = 'absolute';
        distBox.style.color = '#000000';
        distBox.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
        distBox.style.padding = '5px';
        distBox.style.fontSize = '12px';
        distBox.style.borderRadius = '4px';
        distBox.style.border = '1px solid #ffffff';
        distBox.style.pointerEvents = 'none';
        distBox.style.fontFamily = 'Arial, sans-serif';
        distBox.style.transform = 'translate(-50%, -50%)';
        distBox.innerHTML = text;
        document.body.appendChild(distBox);

        const updateDistBoxPosition = () => {
            const vector = midPoint.clone().project(this.viewer.camera);
            const x = (vector.x * 0.5 + 0.5) * window.innerWidth;
            const y = -(vector.y * 0.5 - 0.5) * window.innerHeight;
            distBox.style.left = `${x}px`;
            distBox.style.top = `${y}px`;
        };

        updateDistBoxPosition();
        window.addEventListener('resize', updateDistBoxPosition);
        this.viewer.renderer.domElement.addEventListener('pointermove', updateDistBoxPosition);
        return distBox;
    }

    handleDrawMouseDown(mouse) {
        this.isSelecting = true;
        this.selectionStart.set(mouse.offsetX, mouse.offsetY);

        const createSelectionBox = (posX, posY) => {
            const selBox = document.createElement('div');
            selBox.style.position = 'absolute';
            selBox.style.border = '1px dashed #0000ff';
            selBox.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            selBox.style.borderRadius = '4px';
            selBox.style.height = `0px`;
            selBox.style.width = `0px`;
            selBox.style.left = `${posX}px`;
            selBox.style.top = `${posY}px`;
            document.body.appendChild(selBox);
            return selBox;
        };

        this.selectionBox = createSelectionBox(mouse.offsetX, mouse.offsetY);
    }

    handleDrawMouseMove(mouse) {
        if (this.isSelecting) {
            const currentX = mouse.offsetX;
            const currentY = mouse.offsetY;

            if (this.selectionBox != null) {
                this.selectionBox.style.left = `${Math.min(currentX, this.selectionStart.x)}px`;
                this.selectionBox.style.top = `${Math.min(currentY, this.selectionStart.y)}px`;
                this.selectionBox.style.width = `${Math.abs(currentX - this.selectionStart.x)}px`;
                this.selectionBox.style.height = `${Math.abs(currentY - this.selectionStart.y)}px`;
            }
        }
    }

    handleDrawMouseUp(mouse) {
        if (this.isSelecting) {
            this.isSelecting = false;

            if (this.selectionBox != null) {
                this.selectionBox.style.width = '0px';
                this.selectionBox.style.height = '0px';
                document.body.removeChild(this.selectionBox);
                this.selectionBox = null;
            }

            const selectionEnd = new THREE.Vector2(mouse.offsetX, mouse.offsetY);

            const min = new THREE.Vector2(
                Math.min(this.selectionStart.x, selectionEnd.x) / window.innerWidth * 2 - 1,
                -(Math.max(this.selectionStart.y, selectionEnd.y) / window.innerHeight) * 2 + 1
            );
            const max = new THREE.Vector2(
                Math.max(this.selectionStart.x, selectionEnd.x) / window.innerWidth * 2 - 1,
                -(Math.min(this.selectionStart.y, selectionEnd.y) / window.innerHeight) * 2 + 1
            );

            const newSelectedPoints = new Set();
            this.drawedPoints.forEach(pointElement => {
                const point = pointElement.point;
                const position = pointElement.position.clone();
                const screenPos = position.project(this.viewer.camera);

                if (screenPos.x >= min.x && screenPos.x <= max.x && screenPos.y >= min.y && screenPos.y <= max.y) {
                    newSelectedPoints.add(point);
                    point.material.uniforms.color.value.set(0x0000ff);
                } else {
                    point.material.uniforms.color.value.set(0xff0000);
                }
            });

            this.selectedPoints.forEach(point => {
                if (!newSelectedPoints.has(point)) {
                    point.material.uniforms.color.value.set(0xff0000);
                }
            });

            this.selectedPoints = newSelectedPoints;

            const newSelectedLines = new Set();
            const newSelectedDistBoxInfos = new Set();
            this.lines.forEach(line => {
                const startVector = line.geometry.attributes.position.array.slice(0, 3);
                const endVector = line.geometry.attributes.position.array.slice(3, 6);
                const startPoint = new THREE.Vector3(...startVector).project(this.viewer.camera);
                const endPoint = new THREE.Vector3(...endVector).project(this.viewer.camera);

                if (
                    (startPoint.x >= min.x && startPoint.x <= max.x && startPoint.y >= min.y && startPoint.y <= max.y) &&
                    (endPoint.x >= min.x && endPoint.x <= max.x && endPoint.y >= min.y && endPoint.y <= max.y)
                ) {
                    newSelectedLines.add(line);
                    line.material.color.set(0x0000ff);
                    this.distanceInfos.forEach(distance => {
                        if (distance.line === line) {
                            distance.element.style.color = '#0000ee';
                            // this.selectedDistBoxInfos.add({box: distance.element, value: distance.value});
                            newSelectedDistBoxInfos.add({box: distance.element, value: distance.value});
                        } else {
                            distance.element.style.color = '#000000';
                        }
                    });
                } else {
                    line.material.color.set(0xff0000);
                }
            });

            this.selectedLines.forEach(line => {
                if (!newSelectedLines.has(line)) {
                    line.material.color.set(0xff0000);
                    this.distanceInfos.forEach(distance => {
                        if (distance.line === line) {
                            distance.element.style.color = '#000000';
                            // const deselectDist = {box: distance.element, value: distance.value};
                            // this.selectedDistBoxInfos.delete(deselectDist);
                        }
                    });
                }
            });

            this.selectedDistBoxInfos = newSelectedDistBoxInfos;
            this.selectedLines = newSelectedLines;
        }
    }

    createSettingDialog(distance) {
        const dialog = document.createElement('div');
        dialog.style.position = 'fixed';
        dialog.style.left = '50%';
        dialog.style.top = '50%';
        dialog.style.transform = 'translate(-50%, -50%)';
        dialog.style.backgroundColor = 'rgba(255,255,255,0.95)';
        dialog.style.padding = '20px';
        dialog.style.borderRadius = '10px';
        dialog.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
        dialog.style.zIndex = '1000';
        dialog.style.width = '200px';
        dialog.style.fontFamily = 'Arial, sans-serif';
        dialog.style.animation = 'fadeIn 0.3s';
        dialog.style.cursor = 'move';

        const styleSheet = document.styleSheets[0];
        styleSheet.insertRule(`
            @keyframes fadeIn {
                from { opacity: 0; }
                to { opacity: 1; }
            }
        `, styleSheet.cssRules.length);

        let dispDistance = ``;
        if (distance !== null) {
            dispDistance = `${distance.toFixed(3)}`;
        }
        dialog.innerHTML = `
            <div style="font-size: 14px; color: #555; margin-bottom: 10px;">
                Current distance: ${dispDistance} m
            </div>
            <label style="display: block; margin-bottom: 10px; font-size: 14px; color: #555;">
                <span style="margin-right: 10px;">Calibrate to:</span>
                <input type="number" id="calibratedDist" value="${dispDistance}" step="0.1" style="width: calc(100% - 150px); padding: 5px; margin-top: 5px; font-size: 14px; border: 1px solid #ccc; border-radius: 5px;">
                <span style="margin-left: 10px;">m</span>
            </label>
            <label style="display: block; margin-bottom: 10px; font-size: 14px; color: #555;">
                Line Width:
                <input type="number" id="lineWidth" value="${this.lineWidthSetting}" step="0.01" style="width: 100%; padding: 5px; margin-top: 5px; font-size: 14px; border: 1px solid #ccc; border-radius: 5px;">
            </label>
            <label style="display: block; margin-bottom: 20px; font-size: 14px; color: #555;">
                Point Size:
                <input type="number" id="pointSize" value="${this.pointSizeSetting}" step="0.01" style="width: 100%; padding: 5px; margin-top: 5px; font-size: 14px; border: 1px solid #ccc; border-radius: 5px;">
            </label>
            <div style="display: flex; justify-content: space-between;">
                <button id="saveSettings" style="flex: 1; padding: 10px; background-color: #007bff; color: #fff; border: none; border-radius: 5px; cursor: pointer; font-size: 14px; margin-right: 10px;">Save</button>
                <button id="closeDialog" style="flex: 1; padding: 10px; background-color: #6c757d; color: #fff; border: none; border-radius: 5px; cursor: pointer; font-size: 14px;">Cancel</button>
            </div>
        `;

        return dialog;
    }

    showSettingsDialog() {
        this.settingDialogClosed = false;
        let orignalDistance = NaN;

        for (const item of this.selectedDistBoxInfos) {
            orignalDistance = item.value;
            break;
        }


        const dialog = this.createSettingDialog(orignalDistance);

        let isDragging = false;
        let startX;
        let startY;
        let initialLeft;
        let initialTop;

        const onDragMouseDown = (event) => {
            if (event.target.tagName !== 'LABEL' && event.target.tagName !== 'INPUT' && event.target.tagName !== 'BUTTON') {
                isDragging = true;
                startX = event.clientX;
                startY = event.clientY;
                const rect = dialog.getBoundingClientRect();
                initialLeft = rect.left;
                initialTop = rect.top;
                document.addEventListener('mousemove', onDragMouseMove);
                document.addEventListener('mouseup', onDragMouseUp);
            }
        };

        const onDragMouseMove = (event) => {
            if (isDragging) {
                const dx = event.clientX - startX;
                const dy = event.clientY - startY;
                dialog.style.left = `${initialLeft + dx}px`;
                dialog.style.top = `${initialTop + dy}px`;
                dialog.style.transform = 'none';
            }
        };

        const onDragMouseUp = () => {
            isDragging = false;
            document.removeEventListener('mousemove', onDragMouseMove);
            document.removeEventListener('mouseup', onDragMouseUp);
        };

        dialog.addEventListener('mousedown', onDragMouseDown);

        const saveSettings = () => {
            const calibratedDistance = parseFloat(document.getElementById('calibratedDist').value);
            this.lineWidthSetting = parseFloat(document.getElementById('lineWidth').value);
            this.pointSizeSetting = parseFloat(document.getElementById('pointSize').value);

            if (calibratedDistance != NaN && orignalDistance != NaN) {
                this.scaleFactorSetting = calibratedDistance /orignalDistance;
            }

            document.body.removeChild(dialog);
            this.updateSceneSettings();
            this.settingDialogClosed = true;
            document.removeEventListener('keydown', onKeyPress);
        };

        const closeDialog = () => {
            document.body.removeChild(dialog);
            this.settingDialogClosed = true;
            document.removeEventListener('keydown', onKeyPress);
        };

        dialog.querySelector('#saveSettings').addEventListener('click', saveSettings);
        dialog.querySelector('#closeDialog').addEventListener('click', closeDialog);

        const onKeyPress = (event) => {
            if (event.key === 'Enter') {
                saveSettings();
            }
        };

        document.addEventListener('keydown', onKeyPress);
        document.body.appendChild(dialog);
    }

    updateSceneSettings() {
        this.drawedPoints.forEach(elem => {
            elem.point.material.uniforms.size.value = this.pointSizeSetting;
            // point.point.material.uniforms.scale.value = window.innerHeight / 2;
        });

        this.lines.forEach(line => {
            line.material.linewidth = this.lineWidthSetting;
            line.material.needsUpdate = true;
        });

        this.distanceInfos.forEach(distance => {
            const points = distance.line.geometry.attributes.position.array;
            const start = new THREE.Vector3(points[0], points[1], points[2]);
            const end = new THREE.Vector3(points[3], points[4], points[5]);
            const midPoint = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
            const distanceValue = start.distanceTo(end) * this.scaleFactorSetting;
            distance.element.innerHTML = `${distanceValue.toFixed(3)} m`;

            const updateDistBoxPosition = () => {
                const vector = midPoint.clone().project(this.viewer.camera);
                const x = (vector.x * 0.5 + 0.5) * window.innerWidth;
                const y = -(vector.y * 0.5 - 0.5) * window.innerHeight;
                distance.element.style.left = `${x}px`;
                distance.element.style.top = `${y}px`;
            };

            updateDistBoxPosition();
            window.addEventListener('resize', updateDistBoxPosition);
            this.viewer.renderer.domElement.addEventListener('pointermove', updateDistBoxPosition);
        });
    }

    deleteMeasurePoints() {
        this.selectedPoints.forEach(point => {
            if (this.showCoordinates) {
                document.body.removeChild(point.userData.coordDiv);
            }
            this.viewer.threeScene.remove(point);
            const idx = this.drawedPoints.findIndex(p => p.point.uuid === point.uuid);
            this.drawedPoints.splice(idx, 1);
        });
        this.selectedPoints.clear();

        this.selectedLines.forEach(line => {
            this.viewer.threeScene.remove(line);
            this.lines.splice(this.lines.indexOf(line), 1);
        });
        this.selectedLines.clear();

        this.selectedDistBoxInfos.forEach(boxInfo => {
            document.body.removeChild(boxInfo.box);
        });
        this.selectedDistBoxInfos.clear();
    }
}
